import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Rocket from '../components/common/Rocket'
import Typography from '../components/common/Typography'
import SEO from '../components/Layout/SEO/SEO'

import { NextGenImage } from '../interfaces/common'

import NotFoundImageWebp from '../assets/images/page-not-found.webp'
import NotFoundImage from '../assets/images/page-not-found.png'

export default function NotFoundPage() {
  const notFoundImage: NextGenImage = {
    webPUrl: NotFoundImageWebp,
    fallbackUrl: NotFoundImage,
    alt: 'Page not found',
  }

  return (
    <>
      <SEO
        title="Pop Rocket | Seite nicht gefunden"
        description="Wir liefern Interaktionen, die begeistern. Gamification, motivierendes Design und maßgeschneiderte Entwicklung sorgen für unvergessliche digitale Erlebnisse."
        tabTitle="Seite nicht gefunden"
      />
      <Layout>
        <StyledPageContainer>
          <StyledTextWrapper>
            <StyledHeadline type="h1">Mission control, </StyledHeadline>
            <StyledHeadline type="h1">we have a problem.</StyledHeadline>
            <StyledParagraph type="h3">Page not found. 404.</StyledParagraph>
            <Link to="/">
              <StyledButton>
                <StyledRocketWrapper>
                  <StyledRocket />
                </StyledRocketWrapper>
                Bring me Home
              </StyledButton>
            </Link>
          </StyledTextWrapper>
          <StyledImageWrapper>
            <picture>
              <source srcSet={notFoundImage.webPUrl} type="image/webp" />
              <source srcSet={notFoundImage.fallbackUrl} type="image/png" />
              <StyledImage
                alt={notFoundImage.alt}
                src={notFoundImage.fallbackUrl}
              />
            </picture>
          </StyledImageWrapper>
        </StyledPageContainer>
      </Layout>
    </>
  )
}

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: left;
  width: 100%;
  max-width: 118rem;
  margin: 2rem auto;
  padding: 0 2.4rem;

  height: 100%;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    flex-direction: row;
    padding: 0 3.2rem;
    margin: initial;
    height: auto;
  }
`

const StyledTextWrapper = styled.div`
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    text-align: left;
  }
`

const StyledImageWrapper = styled.div`
  width: 100%;
  margin: 4rem 0 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 0;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    width: 130%;
  }
`

const StyledImage = styled.img`
  width: 100%;
`

const StyledHeadline = styled(Typography)`
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 4.7rem;
  margin: 0 auto;
  padding: 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 4rem;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    font-size: 5.3rem;
    line-height: 6.5rem;
  }
`
const StyledParagraph = styled(Typography)`
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.global.accent};
  padding: 2rem 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 2.8rem;
    line-height: 5rem;
    padding: 0;
  }
  ${({ theme }) => theme.breakpoints.queries.md} {
    font-size: 3.6rem;
    line-height: 7.1rem;
  }
`
const StyledButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  justify-items: center;
  width: 25rem;
  height: 6rem;
  margin: 0 auto;
  background-color: transparent;
  border: 0.1rem solid ${({ theme }) => theme.palette.global.accent};
  border-radius: 1rem;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.palette.company.prlMiddleGrey};
  transition: 0.3s all ease-in-out;
  transition-property: background-color, color, border;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.company.prlYellow};
    color: ${({ theme }) => theme.palette.global.white};
    border: 0.1rem solid transparent;
    svg {
      fill: ${({ theme }) => theme.palette.global.white};
    }
  }
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 4rem 0 0;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    font-size: 3.2rem;
    width: 34rem;
    height: 8rem;
  }
`

const StyledRocketWrapper = styled.div`
  margin: 1rem 0 0;
  svg {
    fill: ${({ theme }) => theme.palette.company.prlMiddleGrey};
    transition: 0.3s fill ease-in-out;
  }
`
const StyledRocket = styled(Rocket)`
  width: 80%;
`
