import React from 'react'
import useBreakpoint from '../../hooks/useBreakpoint'

const Rocket: React.FC = () => {
  const isDesktop = useBreakpoint('md')
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={isDesktop ? '40' : '30'}
      height={isDesktop ? '40' : '30'}
      viewBox="0 0 1000.000000 1000.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
        fill="current"
        stroke="none"
      >
        <path
          d="M7750 9994 c-727 -49 -1290 -198 -1835 -487 -176 -93 -209 -112 -392
 -235 -539 -361 -1051 -899 -1521 -1599 l-116 -173 -1045 0 c-839 -1 -1061 -3
 -1121 -14 -162 -30 -334 -112 -481 -231 -146 -118 -134 -97 -699 -1225 -468
 -936 -519 -1042 -530 -1109 -38 -227 102 -454 322 -523 57 -17 116 -18 1116
 -18 l1056 0 -260 -262 c-286 -289 -304 -314 -344 -472 -37 -145 -21 -294 46
 -434 35 -75 54 -95 593 -635 306 -307 581 -574 611 -594 133 -89 333 -124 487
 -85 166 42 190 59 481 346 l262 260 0 -1053 c0 -1168 -3 -1107 67 -1223 96
 -161 289 -249 473 -218 67 11 175 63 1100 525 1130 565 1109 553 1239 712 113
 139 189 295 222 458 18 84 19 155 19 1137 l0 1047 128 87 c587 403 920 682
 1241 1042 279 314 450 555 625 880 258 478 415 1005 482 1612 34 316 29 1095
 -11 1480 -26 253 -90 621 -119 692 -21 50 -88 120 -142 150 -90 48 -474 113
 -854 144 -173 14 -968 27 -1100 18z m-79 -1733 c416 -92 679 -501 593 -919
 -117 -568 -784 -810 -1249 -455 -124 96 -247 296 -279 457 -35 171 -14 331 64
 491 161 334 511 505 871 426z"
        />
      </g>
    </svg>
  )
}

export default Rocket
